@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;600&display=swap');

.box5 {
    height: 532px;
    position: relative;
}

.box5_overlap_group {
    display: flex;
    flex-direction: column;
    padding: 157px 180px 0 0px;
    gap: 60px;
    align-items: flex-end;
}

.box5_bg {
    height: 532px;
    width: 100%;
    background-image : url(./img/box5_bg.png);
    position: absolute;
    left : 0px;
    top : 0px;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.box5_txt_group {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
    z-index: 900;
}

.box5_txt_top {
    color: #ffffff;
    font-family: "Rajdhani-SemiBold", Helvetica;
    font-size: 28px;
    font-weight: 600;
    letter-spacing: -0.56px;
    line-height: 39.2px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.box5_txt_bottom {
    color: #ffffff;
    font-family: "Rajdhani-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.32px;
    line-height: 22.4px;
    opacity: 0.8px;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

.box5_btn {
    all: unset;
    box-sizing: border-box;
    height: 60px;
    width: 201px;
    cursor: pointer;
}

.box5_btn_txt_group {
    align-items: center;
    display: inline-flex;
    gap: 10px;
    height: 60px;
    padding: 0 20px;
    position: absolute;
}

.box5_btn_bg {
    position: relative; /* 상대적으로 위치 지정된 부모 내에서 절대 위치 지정 */
    z-index: 1;
}

.box5_btn_txt {
    color: #ffffff;
    font-family: "Rajdhani-SemiBold", Helvetica;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.36px;
    line-height: 25.2px;
    position: relative;
    z-index: 2;
}