.conatact {
    width: 100%;   
}

.contact_overlap_group {
    height: 2050px;
    position: relative;
    z-index: 900;
}

.contact_title {
    color: #ffffff;
    font-family: "Rajdhani", Helvetica;
    font-size: 80px;
    font-weight: 600;
    letter-spacing: -1.6px;
    line-height: 112px;
    white-space: nowrap;
    margin: 0;
    padding: 200px 0 0 60px;
    position: relative;
    z-index: 900;
}

.contact_txt_group_total {
    padding: 30px 0 0 360px;
    position: relative;
    z-index: 900;
}

.contact_txt_group {
    display: flex;
    gap : 30px;
}

.contact_txt {
    color: #FFFFFF;
    font-family: 'Rajdhani', Helvetica, sans-serif;
    font-weight: 400;
    font-size: 79px; 
    letter-spacing: -1.6px;
    line-height: 112px;
    white-space: nowrap;
  }

  .conatact_vector {
    margin: 0;
    filter: contrast(150%) brightness(120%);
    z-index: 900;
    position: relative;
    height: 55px;
    width: 79px;
    padding-top: 30px;
  }

  .contact_txt_group2 {
    display: flex;
}

  .contact_txt2 {
    color: #FFFFFF;
    font-family: 'Rajdhani', Helvetica, sans-serif;
    font-weight: 400;
    font-size: 79px; 
    letter-spacing: -1.6px;
    line-height: 112px;
    white-space: nowrap;
  }

  .contact_txt2_gray {
    color: #777777;
    font-family: 'Rajdhani', Helvetica, sans-serif;
    font-weight: 400;
    font-size: 79px; 
    letter-spacing: -1.6px;
    line-height: 112px;
    white-space: nowrap;
  }

.contact_txt_group3 {
    display: flex;
    gap : 10px;
    align-items: center;
}

.contact_txt3 {
    color: #777777;
    font-family: 'Rajdhani', Helvetica, sans-serif;
    font-weight: 400;
     font-size: 79px; 
    letter-spacing: -1.6px;
    line-height: 112px;
    white-space: nowrap;
}

.contact_form_txt_group {
    position: relative;
    z-index: 900;
    padding: 100px 0 0 660px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.contact_form_txt {
    color: #16d7cd;
    font-family: 'Pretendard', Helvetica;
    font-weight: 600;
    font-size: 28px; 
    letter-spacing: -0.56px;
    line-height: 39.2px;
    white-space: nowrap;
}

.contact_form_txt2 {
    padding-left: 10px;
    color: #ffffff;
    font-family: 'Pretendard', Helvetica;
    font-weight: 600;
    font-size: 28px; 
    letter-spacing: -0.56px;
    line-height: 39.2px;
    white-space: nowrap;
}

.contact_form_group_txt2 {
    color: #16d7cd;
    overflow: hidden;
    text-overflow: ellipsis;
}

.contact_form_txt3 {
    color: #444444;
    font-family: 'Pretendard', Helvetica;
    font-weight: 400;
    font-size: 14px; 
    letter-spacing: -0.28px;
    line-height: 19.6px;
    white-space: nowrap;
}

.contact_form_button_group_total {
    padding: 30px 0 0 660px;
    position: relative;
    z-index: 900;
}

.contact_form_button_group {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap : 10px;
    width: 900px;
}

.contact_form {
    padding: 25px 0 0 660px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: relative;
    z-index: 900;
}

.contact_form_group {
    align-items: center;
    display: flex;
    width: 880px;
    padding: 10px;
    border-bottom: 1px solid #333333;
}

.contact_form_group_file {
    align-items: center;
    display: flex;
    width: 880px;
    padding: 10px;
    border: 1px dashed #dddddd3d;
}

.contact_form input{
    width: 100%;
    height: 40px;
    background-color: transparent;
    border: transparent;
    color: #FFFFFF;
    font-size: 16px;
}

.contact_form_img {
    cursor: pointer;
}

.contact_form_group_txt {
    color: #ffffff;
    font-family: 'Pretendard', Helvetica;
    font-weight: 500;
    font-size: 16px; 
    letter-spacing: -0.32px;
    line-height: 22.4px;
    white-space: nowrap;
}

.contact_form_checkbox_group {
    padding: 80px 0 0 1327px;
    display: flex;
    gap: 10px;
    position: relative;
    z-index: 900;
}

.contact_form_checkbox_txt {
    color: #777777;
    font-family: 'Pretendard', Helvetica;
    font-weight: 500;
    font-size: 14px; 
    letter-spacing: -0.30px;
    line-height: 22.4px;
    white-space: nowrap;
}

.contact_multi_line_input {
    width: 894px;
    resize: none;
    border: 1px solid #333333;
    background-color: black;
    color: #FFFFFF;
    font-size: 16px;
}

.contact_form_checkbox {
  position: absolute;
  top: 83px;
  left: 1331px;
  pointer-events: none;
}

.contact_form_submit_btn {
    all: unset;
    box-sizing: border-box;
    height: 60px;
    width: 201px;
    cursor: pointer;
    padding: 60px 0 0 1359px;
}

.contact_form_submit_btn_group {
    align-items: center;
    display: inline-flex;
    gap: 10px;
    height: 60px;
    padding: 0 20px;
    position: absolute;
}

.contact_form_submit_btn_bg {
    position: relative; /* 상대적으로 위치 지정된 부모 내에서 절대 위치 지정 */
    z-index: 1;
}

.contact_form_submit_btn_txt {
    color: #ffffff;
    font-family: "Rajdhani-SemiBold", Helvetica;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.36px;
    line-height: 25.2px;
    position: relative;
    z-index: 2;
}

.contact_bg {
    height: 2050px;
    width: 1920px;
    width: 100%;
    background-image : url(./img/contact_bg.png);
    background-size: 100%;
    position: absolute;
    left : 0px;
    top : 0px;
    z-index: 0;
}

/* 모바일 */

.mobile_conatact {
    width: 100%;   
}

.mobile_contact_overlap_group {
    height: 1600px;
    position: relative;
    z-index: 900;
    margin: 0 20px;
    border-left: 1px solid #444444;
    border-right: 1px solid #444444;
}

.mobile_contact_title {
    color: #ffffff;
    font-family: "Rajdhani", Helvetica;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: -1.6px;
    line-height: 112px;
    white-space: nowrap;
    margin: 0;
    padding: 110px 0 0 0px;
    position: relative;
    z-index: 900;
}

.mobile_contact_txt_group_total {
    padding: 60px 0 0 0px;
    position: relative;
    z-index: 900;
}

.mobile_contact_txt_group {
    display: flex;
    gap : 10px;
}

.mobile_contact_txt {
    color: #FFFFFF;
    font-family: 'Rajdhani', Helvetica, sans-serif;
    font-weight: 400;
    font-size: 24px; 
    letter-spacing: -0.48px;
    line-height: 33.6px;
    white-space: nowrap;
  }

.mobile_conatact_vector {
    margin: 0;
    filter: contrast(150%) brightness(120%);
    z-index: 900;
    position: relative;
    height: 17px;
    width: 25px;
    padding-top: 10px;
  }

.mobile_contact_txt_group2 {
    display: flex;
}

.mobile_contact_txt2 {
    color: #FFFFFF;
    font-family: 'Rajdhani', Helvetica, sans-serif;
    font-weight: 400;
    font-size: 24px; 
    letter-spacing: -0.48px;
    line-height: 33.6px;
    white-space: nowrap;
  }

.mobile_contact_txt2_gray {
    color: #777777;
    font-family: 'Rajdhani', Helvetica, sans-serif;
    font-weight: 400;
    font-size: 24px; 
    letter-spacing: -0.48px;
    line-height: 33.6px;
    white-space: nowrap;
  }

.mobile_contact_txt_group3 {
    display: flex;
    gap : 5px;
    align-items: center;
}

.mobile_contact_txt3 {
    color: #777777;
    font-family: 'Rajdhani', Helvetica, sans-serif;
    font-weight: 400;
    font-size: 24px; 
    letter-spacing: -0.48px;
    line-height: 33.6px;
    white-space: nowrap;
}

.mobile_contact_form_txt_group {
    position: relative;
    z-index: 900;
    padding: 60px 0 0 40px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.mobile_contact_form_txt {
    color: #16d7cd;
    font-family: 'Pretendard', Helvetica;
    font-weight: 600;
    font-size: 20px; 
    letter-spacing: -0.4px;
    line-height: 28px;
    white-space: nowrap;
}

.mobile_contact_form_txt2 {
    padding-left: 10px;
    color: #ffffff;
    font-family: 'Pretendard', Helvetica;
    font-weight: 600;
    font-size: 20px; 
    letter-spacing: -0.4px;
    line-height: 28px;
    white-space: nowrap;
}

.mobile_contact_form_group_txt2 {
    color: #16d7cd;
}

.mobile_contact_form_txt3 {
    color: #444444;
    font-family: 'Pretendard', Helvetica;
    font-weight: 400;
    font-size: 12px; 
    letter-spacing: -0.24px;
    line-height: 16.8px;
    white-space: nowrap;
}

.mobile_contact_form_button_group_total {
    padding: 30px 0 0 40px;
    position: relative;
    z-index: 900;
}

.mobile_contact_form_button_group {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap : 10px;
    width: 300PX;
}

.mobile_contact_form {
    padding: 20px 0 0 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    z-index: 900;
}

.mobile_contact_form_group {
    align-items: center;
    display: flex;
    width: 275px;
    padding: 10px;
    border-bottom: 1px solid #333333;
}

.mobile_contact_form_group_file {
    align-items: center;
    display: flex;
    width: 293px;
    padding: 10px;
    border: 1px dashed #dddddd3d;
    box-sizing: border-box;
    justify-content: end;
}

.mobile_contact_form input{
    width: 100%;
    height: 40px;
    background-color: transparent;
    border: transparent;
    color: #FFFFFF;
    font-size: 16px;
}

.mobile_contact_form_img {
    cursor: pointer;
}

.mobile_contact_form_group_txt {
    color: #ffffff;
    font-family: 'Pretendard', Helvetica;
    font-weight: 500;
    font-size: 14px; 
    letter-spacing: -0.28px;
    line-height: 19.6px;
    white-space: nowrap;
}

.mobile_contact_form_group_file_txt {
    color: #ffffff;
    font-family: 'Pretendard', Helvetica;
    font-weight: 500;
    font-size: 14px; 
    letter-spacing: -0.28px;
    line-height: 19.6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mobile_contact_form_checkbox_group {
    padding: 30px 0 0 109px;
    display: flex;
    gap: 10px;
    position: relative;
    z-index: 900;
}

.mobile_contact_form_checkbox_txt {
    color: #777777;
    font-family: 'Pretendard', Helvetica;
    font-weight: 500;
    font-size: 13px; 
    letter-spacing: -0.28px;
    line-height: 22.4px;
    white-space: nowrap;
}

.mobile_contact_form_checkbox {
  position: absolute;
  top: 33px;
  left: 113px;
  pointer-events: none;
}

.mobile_contact_multi_line_input {
    width: 290px;
    resize: none;
    border: 1px solid #333333;
    background-color: black;
    color: #FFFFFF;
    font-size: 16px;
}

.mobile_contact_form_submit_btn {
    all: unset;
    box-sizing: border-box;
    height: 60px;
    width: 201px;
    cursor: pointer;
    padding: 60px 0 0 133px;
}

.mobile_contact_form_submit_btn_group {
    align-items: center;
    display: inline-flex;
    gap: 10px;
    height: 60px;
    padding: 0 20px;
    position: absolute;
}

.mobile_contact_form_submit_btn_bg {
    position: relative; /* 상대적으로 위치 지정된 부모 내에서 절대 위치 지정 */
    z-index: 1;
}

.mobile_contact_form_submit_btn_txt {
    color: #ffffff;
    font-family: "Rajdhani-SemiBold", Helvetica;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.36px;
    line-height: 25.2px;
    position: relative;
    z-index: 2;
}

.mobile_contact_bg {
    height: 1600px;
    width: 100%;
    background-image : url(./img/mobile_contact_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    left : 0px;
    top : 0px;
    z-index: 0;
}