.mobile_header {
    align-items: center;
    display: flex;
    height: 65px;
    justify-content: space-between;
    padding: 0px 20px;
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    z-index: 999;
    transition: background-color 0.3s;
}

.mobile_layer {
    width: 100%;
    height: auto;
    position: relative;
    cursor: pointer;
}
  
.mobile_navbar {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 50px;
    justify-content: flex-end;
    position: relative;
}
  
.mobile_header_text_wrapper {
    color: #ffffff;
    font-family: 'Rajdhani', Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.4px;
    line-height: 28px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
    text-decoration: none;
    cursor: pointer;
}

.mobile_menu-icon {
    display: block;
    cursor: pointer;
}

/* 네비게이션 메뉴를 숨기고 햄버거 아이콘만 보이도록 합니다 */
.mobile_navbar {
    display: none;
}