@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@400&display=swap');

.box {
    height: 679px;
    width: 100%;
}

.main {
    height: 679px;
    width: 100%;
}

.overlap-group {
    height: 679px;
    position: relative;
    width: 100%;
}

.v-banner {
    background-image : url(./img/image43.png);
    background-size: 100% 100%;
    height: 679px;
    position: absolute;
    left : 0;
    top : 0;
    width: 100%;
    background-repeat: no-repeat;
}

.bg {
    background-color: #000000cc;
    height: 679px;
}

.overlap-group-flex {
    display: flex;
    position: relative;
}

.we-utilize-AI-to {
    color : #ffffff;
    font-family: 'Rajdhani', Helvetica, sans-serif;
    font-size: 80px;
    font-weight: 400;
    letter-spacing: -1.6px;
    line-height: 96px;
    text-align: left;
    margin: 0;
    padding: 180px 0px 115px 60px;
    height: 384px;
}

.txt {
    width: 396px;
    margin-left: 166px;
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.vector {
    height: 140px;
    width: 19px;
    transform: scaleX(-1);
    padding: 181px 0px 0px 0px;
}

.img {
    height: 140px;
    width: 19px;
    padding: 181px 0px 0px 0px;
}

.onclev-is-an-IT {
    color : #ffffff;
    font-family: 'Rajdhani', Helvetica, sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.48px;
    line-height: 33.6px;
    text-align: left;
    padding: 200px 0px 0px 0px;
    margin: 0;
}

