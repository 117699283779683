@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;600&display=swap');

.mobile_box4 {
    position: relative;
    height: 1226px;
}

.mobile_box4_overlap_group {
    display: flex;
    flex-direction: column;
}

.mobile_box4_bg {
    height: 1226px;
    width: 100%;
    background-image : url(./img/box4_bg.png);
    background-size: 100% 100%; 
    position: absolute;
    left : 0px;
    top : 0px;
    z-index: 0;
    background-repeat: no-repeat;
}

.mobile_box4_txt_top_group {
    position: relative;
    z-index: 900;
    align-items: center;
    display: inline-flex;
    gap: 10px;
}

.mobile_box4_txt_top {
    color: #999999;
    font-family: 'Rajdhani', Helvetica, sans-serif;
    font-weight: 400;
    font-size: 24px; 
    letter-spacing: -0.48px;
    line-height: 33.6px;
    white-space: nowrap;
    z-index: 900;
    margin: 0;
    padding-left: 20px;
}

.mobile_box4_txt_ovj {
    position: relative;
}

.mobile_box4_txt_bottom{
    color: #777777;
    font-family: "Pretendard-Regular", Helvetica;
    font-weight: 400;
    font-size: 14px; 
    letter-spacing: -0.28px;
    line-height: 19.6px;
    padding-left: 60px;
    padding-top: 20px;
    margin: 0;
    z-index: 900;
}

.mobile_box4_hover {
    display: flex;
    gap:282px;
}

.mobile_box4_txt_hover {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.mobile_box4_txt_hover_title {
    color: #dddddd;
    font-family: 'Rajdhani', Helvetica, sans-serif;
    font-weight: 600;
    font-size: 40px; 
    letter-spacing: -0.8px;
    line-height: 56px;
    white-space: nowrap;
    width: fit-content;
}

.mobile_box4_txt_hover_content {
    color: #dddddd;
    font-family: "Pretendard-Regular", Helvetica;
    font-weight: 400;
    font-size: 13.5px; 
    letter-spacing: -0.32px;
    line-height: 22.4px;
    width: fit-content;
}
