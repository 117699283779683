
.mobile_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: 'rgba(0, 0, 0, 0.5)';
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile_modal {
    background-color: #000000;
    padding: 20px;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    max-width: 750px;
    opacity: 0.9;
}

.mobile_modal_navbar {
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 30px;
    padding-top: 100px;
}

.mobile_modal_header_text_wrapper {
    color: #ffffff;
    font-family: 'Rajdhani', Helvetica, sans-serif;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: -0.4px;
    line-height: 28px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
    text-decoration: none;
    cursor: pointer;
}

.mobile_closeBtn {
    color: #ffffff;
    float: right;
    font-size: 3em;
    cursor: pointer;
}
