.header {
    align-items: center;
    display: flex;
    height: 100px;
    justify-content: center;
    padding: 0px 60px;
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    z-index: 999;
    transition: background-color 0.3s;
}

.layer {
    height: 34px;
    position: relative;
    width: 135px;
    cursor: pointer;
}
  
.navbar {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 50px;
    justify-content: flex-end;
    position: relative;
}
  
.header_text_wrapper {
    color: #ffffff;
    font-family: 'Rajdhani', Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.4px;
    line-height: 28px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
    text-decoration: none;
    cursor: pointer;
}

  .menu-icon {
    display: none;
    cursor: pointer;
}