p {
    margin: 0px;
}

.company {
    width: 100%;
}

.company_overlap_group {
    height: 2653px;
    position: relative;
    z-index: 900;
}

.company_title {
    color: #ffffff;
    font-family: "Rajdhani", Helvetica;
    font-size: 80px;
    font-weight: 600;
    letter-spacing: -1.6px;
    line-height: 112px;
    white-space: nowrap;
    margin: 0;
    padding: 200px 0 0 60px;
    position: relative;
    z-index: 900;
}

.company_txt_top_group {
    display: flex;
    flex-direction: column;
    padding: 240px 0 0 60px;
    position: relative;
    z-index: 900;
}

.company_txt_top_group_title {
    color: #16d7cd;
    font-family: "Pretendard", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.32px;
    line-height: 22.4px;
    white-space: nowrap;
    height: 22px;
}

.company_txt_top_group_top {
    color: #ffffff;
    font-family: "Pretendard", Helvetica;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: -0.8px;
    line-height: 56px;
    padding: 30px 0 0 300px;
    margin: 0px;
}

.company_txt_top_group_middle {
    color: #777777;
    font-family: "Pretendard", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -1.7px;
    line-height: 22.4px;
    padding: 40px 0 0 300px;
}

.company_txt_top_group_title2 {
    padding-top: 82px;
}

.company_txt_top_group_bottom {
    color: #ffffff;
    font-family: "Pretendard", Helvetica;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: -0.8px;
    line-height: 56px;
    padding: 30px 0 0 300px;
}

.company_pointbox_group {
    padding: 114px 0 0 60px;
    height: 400px;
    position: relative;
    z-index: 900;
    display: flex;
}

.company_pointbox_group_frame {
    padding: 60px;
    display: flex;
    flex-direction: column;
    width: 480px;
    gap: 30px;
    margin: 0;
}

.company_pointbox_group_txt {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.company_pointbox_group_title {
    color: #ffffff;
    font-family: "Rajdhani", Helvetica;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: -0.64px;
    line-height: 44.8px;
    white-space: nowrap;
    width: fit-content;
}

.company_pointbox_group_content {
    color: #777777;
    font-family: "Pretendard", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.32px;
    line-height: 22.4px;
}

.company_img {
    display: flex;
    align-self: flex-end;
    height: 120px;
}

.company_txt_top_group_title3 {
    position: relative;
    z-index: 900;
    padding-left: 60px;
    padding-top: 100px;
}
.company_bg {
    height: 2653px;
    width: 100%;
    background-image : url(./img/company_bg.png);
    background-size: 100%;
    position: absolute;
    left : 0px;
    top : 0px;
    z-index: 0;
}

/* 모바일 */

.mobile_company_overlap_group {
    height: 3100px;
    position: relative;
    z-index: 900;
    margin: 0 20px;
    border-left: 1px solid #444444;
    border-right: 1px solid #444444;
}

.mobile_company_title {
    color: #ffffff;
    font-family: "Rajdhani", Helvetica;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: -1.6px;
    line-height: 112px;
    white-space: nowrap;
    margin: 0;
}

.mobile_company_txt_top_group {
    display: flex;
    flex-direction: column;
    padding: 110px 0 0 0;
    position: relative;
    z-index: 900;
}

.mobile_company_txt_top_group_title {
    color: #16d7cd;
    font-family: "Pretendard", Helvetica;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.24px;
    line-height: 16.8px;
    white-space: nowrap;
    height: 17px;
}

.mobile_company_txt_top_group_top {
    color: #ffffff;
    font-family: "Pretendard", Helvetica;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -1.48px;
    line-height: 33.6px;
    padding: 30px 0 0 20px;
    margin: 0px;
    white-space: nowrap;
}

.mobile_company_txt_top_group_middle {
    color: #777777;
    font-family: "Pretendard", Helvetica;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.28px;
    line-height: 19.6px;
    padding: 20px 0 0 40px;
}

.mobile_company_txt_top_group_title2 {
    padding-top: 60px;
}

.mobile_company_txt_top_group_bottom {
    color: #ffffff;
    font-family: "Pretendard", Helvetica;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -1.48px;
    line-height: 33.6px;
    padding: 30px 0 0 20px;
    white-space: nowrap;
}

.mobile_company_pointbox_group {
    padding: 60px 0px 0 0px;
    position: relative;
    z-index: 900;
    display: flex;
    flex-direction: column;
}

.mobile_company_pointbox_group_frame {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 0;
    border-top: 1px solid #444444;
    border-bottom: 1px solid #444444; 
}

.mobile_company_pointbox_group_txt {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.mobile_company_pointbox_group_title {
    color: #ffffff;
    font-family: "Rajdhani", Helvetica;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: -0.64px;
    line-height: 44.8px;
    white-space: nowrap;
    width: fit-content;
}

.mobile_company_pointbox_group_content {
    color: #777777;
    font-family: "Pretendard", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.28px;
    line-height: 18.6px;
}

.mobile_company_img {
    display: flex;
    align-self: flex-end;
    height: 120px;
    padding-right: 55px;
}

.mobile_company_txt_top_group_title3 {
    position: relative;
    z-index: 900;
    padding-top: 60px;
}
.mobile_company_bg {
    height: 100%;
    width: 100%;
    background-image : url(./img/mobile_company_bg.png);
    background-size: 100%;
    position: absolute;
    left : 0px;
    top : 0px;
    z-index: 0;
}
.mobile_company_title_group {
    display: flex;
    padding: 110px 0 0 0px;
    position: relative;
    z-index: 900;
    gap : 6px;
}
.mobile_company_txt_bottom_group {
    position: relative;
    z-index: 900;
}