.mobile_box4_hover {
    display: flex;
    gap:26px;
    position: relative;
}

.mobile_box4_img_hover {
    width: 30px;
    height: 100%;
    transition: background-image 0.5s ease;
}

.mobile_box4_txt_hover_group {
    display: flex;
    flex-direction: column;
    padding-top: 70px;
    padding-left: 60px;
    position: relative;
    z-index: 900;
}

.mobile_box4_txt_hover {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.mobile_box4_txt_hover_svg {
    position: absolute;
    top : -7px;
    left: 39px;
    z-index: 900;
    opacity: 0;
}

.mobile_box4_txt_hover_title {
    color: #dddddd;
    font-family: 'Rajdhani', Helvetica, sans-serif;
    font-weight: 600;
    font-size: 30px; 
    letter-spacing: -0.64px;
    line-height: 44.8px;
    white-space: nowrap;
    width: fit-content;
}

.mobile_box4_txt_hover_content {
    color: #dddddd;
    font-family: "Pretendard-Regular", Helvetica;
    font-weight: 400;
    font-size: 13.5px; 
    letter-spacing: -0.28px;
    line-height: 19.6px;
    white-space: nowrap;
    width: fit-content;
}
