.company_txt_bottom_group {
    padding-left: 360px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 900;
}

.company_bottom_group {
    display: flex;
}

.company_txt_bottom_title {
    color: #444444;
    font-family: "Pretendard", Helvetica;
    font-size: 56px;
    font-weight: 600;
    letter-spacing: -1.12px;
    line-height: 78.4px;
    white-space: nowrap;
}

.company_txt_bottom_img {
    padding-top: 39px;
    padding-left: 64px;
}

.company_txt_bottom_number {
    padding-top: 18px;
    padding-left: 300px;
    color: #444444;
    font-family: "Pretendard", Helvetica;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: -0.6px;
    line-height: 42px;
    white-space: nowrap;
    text-align: center;
}

.company_txt_bottom_number2 {
    padding-top: 18px;
    padding-left: 315px;
    color: #444444;
    font-family: "Pretendard", Helvetica;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: -0.6px;
    line-height: 42px;
    white-space: nowrap;
    text-align: center;
}

.company_txt_bottom_txt_group {
    padding-top: 28px;
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 214px;
    color: #444444;
    font-family: "Pretendard", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.32px;
    line-height: 22.4px;
    white-space: nowrap;
}