.mobile_box3 {
    position: relative;
    height: 196px;
}

.mobile_box3_overlap_group {
    display: flex;
}

.mobile_box3_bg {
    height: 196px;
    width: 100%;
    background-image : url(./img/box3_bg.png);
    background-size: 100%;
    background-color: black;
    position: absolute;
    left : 0px;
    top : 0px;
    z-index: 0;
}

.mobile_box3_logo {
    height: 27px;
    padding: 60px 0px 0px 20px;
    margin: 0;
    filter: contrast(150%) brightness(120%);
}

.mobile_box3_overlap_txt {
  display: inline;
  color: #FFFFFF;
  font-family: 'Arial', 'Helvetica', sans-serif;
  font-weight: 600;
  font-size: 12px; 
  margin: 0;
  position: relative;
  left: -60px;
  z-index: 900;
  padding: 65px 0px 0px 60px;
  white-space: nowrap;
}

.mobile_box3_overlap_group2 {
    display: flex;
    gap : 10px;
    padding-top: 48px;
}

.mobile_box3_overlap2_txt {
    color: #FFFFFF;
    font-family: 'Rajdhani', Helvetica, sans-serif;
    font-weight: 400;
    font-size: 24px; 
    letter-spacing: -0.48px;
    line-height: 33.6px;
    white-space: nowrap;
    z-index: 900;
    margin: 0;
    padding-left: 20px;
  }

  .mobile_box3_overlap2_txt2 {
    color: #FFFFFF;
    font-family: 'Rajdhani', Helvetica, sans-serif;
    font-weight: 400;
    font-size: 24px; 
    letter-spacing: -0.48px;
    line-height: 33.6px;
    white-space: nowrap;
    z-index: 900;
    margin: 0;
  }

  .mobile_box3_overlap2_txt3 {
    color: #FFFFFF;
    font-family: 'Rajdhani', Helvetica, sans-serif;
    font-weight: 400;
    font-size: 24px; 
    letter-spacing: -0.48px;
    line-height: 33.6px;
    white-space: nowrap;
    z-index: 900;
    margin: 0;
    position: relative;
    padding-left: 20px;
  }