.technology {
    width: 100%;
}

.technology_overlap_group {
    height: 2577px;
    position: relative;
    z-index: 900;
}

.technology_title {
    color: #ffffff;
    font-family: "Rajdhani", Helvetica;
    font-size: 80px;
    font-weight: 600;
    letter-spacing: -1.6px;
    line-height: 112px;
    white-space: nowrap;
    margin: 0;
    padding: 200px 0 0 60px;
    position: relative;
    z-index: 900;
}

.technology_list_group {
    padding: 240px 0 0 60px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 900;
}

.technology_bg {
    height: 2577px;
    width: 1920px;
    width: 100%;
    background-image : url(./img/technology_bg.png);
    background-size: 100%;
    position: absolute;
    left : 0px;
    top : 0px;
    z-index: 0;
}

/* 모바일 */


.mobile_technology {
    width: 100%;
}

.mobile_technology_overlap_group {
    height: 1600px;
    position: relative;
    z-index: 900;
    margin: 0 20px;
    border-left: 1px solid #444444;
    border-right: 1px solid #444444;
}

.mobile_technology_title {
    color: #ffffff;
    font-family: "Rajdhani", Helvetica;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: -1.6px;
    line-height: 112px;
    white-space: nowrap;
    margin: 0;
    position: relative;
    z-index: 900;
}

.mobile_technology_list_group {
    padding: 90px 0 0 0px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 900;
}

.mobile_technology_bg {
    height: 1600px;
    width: 100%;
    background-image : url(./img/mobile_technology_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    left : 0px;
    top : 0px;
    z-index: 0;
}

.mobile_technology_title_group {
    display: flex;
    padding: 110px 0 0 0px;
    position: relative;
    z-index: 900;
    gap : 6px;
}