a {
    text-decoration: none; /* 밑줄 없애기 */
  }

.news {
    width: 100%;
}

.news_overlap_group {
    height: 100%;
    position: relative;
    z-index: 900;
}

.news_title {
    color: #ffffff;
    font-family: "Rajdhani", Helvetica;
    font-size: 80px;
    font-weight: 600;
    letter-spacing: -1.6px;
    line-height: 112px;
    white-space: nowrap;
    margin: 0;
    padding: 200px 0 0 60px;
    position: relative;
    z-index: 900;
}

.news_arrow_img {
    position: absolute;
    z-index: 900;
    left: 467px;
    top: 250px;
}
.news_seach_box_group {
    align-items: center;
    padding: 240px 0 100px 360px;
    display: flex;
    position: relative;
    z-index: 900;
}

.news_button {
  border: none;  /* 버튼의 테두리 제거 */
  background: none;  /* 배경색 제거 */
  padding: 10px;  /* 패딩 추가 */
  cursor: pointer;  /* 마우스 오버 시 커서 변경 */
}

.news_seach_box_input {
    padding: 10px 15px;
    height: 50px;
    width: 321px;
    background-color: black;
    border: none;

    color: #ffffff;
    font-family: "Pretendard", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.32px;
    line-height: 22.4px;
}

.news_seach_box_line {
    padding-left: 20px;
}

.news_seach_box_line2 {
    transform: scaleX(-1);
}

.news_seach_box_result_group {
    padding-left: 1054px;
    /* align-items: center; */
    display: flex;
    align-items: center;
    gap: px;
}

.news_seach_box_result_txt {
    color: #777777;
    font-family: "Pretendard", Helvetica;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.28px;
    line-height: 19.6px;
}

.news_seach_box_result_txt_number {
    color: #16D7CD;
    font-family: "Pretendard", Helvetica;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.36px;
    line-height: 25.2px;
    text-align: right;
    padding-left: 6px;
}

.news_box_group {
    padding-left:360px;
    position: relative;
    z-index: 900;
    display: flex;
    gap: 30px;
}

.news_box {
    display: flex;
    flex-direction: column;
}

.news_box2 {
    display: flex;
    flex-direction: column;
    padding-top: 100px;   
}

.news_box_img {
    height: 685px;
    width: 735px;
}

.news_box_txt_group {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 735px;
}

.news_box_txt_top {
    color: #ffffff;
    font-family: "Pretendard", Helvetica;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.48px;
    line-height: 33.6px;
}

.news_box_txt_bottom {
    color: #cccccc;
    font-family: "Pretendard", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.32px;
    line-height: 22.4px;
}

.news_bg {
    /* height: 3430px; */
    height: 100%;
    width: 1920px;
    width: 100%;
    background-image : url(./img/news_bg.png);
    background-size: 100%;
    position: absolute;
    left : 0px;
    top : 0px;
    z-index: 0;
}

/* 모바일 */

.mobile_news {
    width: 100%;
}

.mobile_news_overlap_group {
    height: 100%;
    position: relative;
    z-index: 900;
    margin: 0 20px;
    border-left: 1px solid #444444;
    border-right: 1px solid #444444;
}

.mobile_news_title {
    color: #ffffff;
    font-family: "Rajdhani", Helvetica;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: -1.6px;
    line-height: 112px;
    white-space: nowrap;
    margin: 0;
    position: relative;
    z-index: 900;
}

.mobile_news_seach_box_group {
    align-items: center;
    padding: 120px 0 0 0px;
    display: flex;
    position: relative;
    z-index: 900;
    justify-content: start;
}

.mobile_news_button {
  border: none;  /* 버튼의 테두리 제거 */
  background: none;  /* 배경색 제거 */
  padding: 10px;  /* 패딩 추가 */
  cursor: pointer;  /* 마우스 오버 시 커서 변경 */
}

.mobile_news_seach_box_input {
    padding: 10px 15px;
    height: 50px;
    width: 212px;
    background-color: black;
    border: none;
    color: #ffffff;
    font-family: "Pretendard", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.32px;
    line-height: 22.4px;
}

.mobile_news_seach_box_line {
    padding-left: 20px;
}

.mobile_news_seach_box_line2 {
    transform: scaleX(-1);
}

.mobile_news_seach_box_result_group {
    align-items: center;
    padding-top: 30px;
    display: flex;
    position: relative;
    z-index: 900;
    justify-content: start;
}

.mobile_news_seach_box_result_txt {
    color: #777777;
    font-family: "Pretendard", Helvetica;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.28px;
    line-height: 19.6px;
}

.mobile_news_seach_box_result_txt_number {
    color: #16D7CD;
    font-family: "Pretendard", Helvetica;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.36px;
    line-height: 25.2px;
    text-align: right;
    padding-left: 6px;
}

.mobile_news_box_group {
    padding-top:30px;
    position: relative;
    z-index: 900;
    display: flex;
    gap: 30px;
    justify-content: start;
}

.mobile_news_box {
    display: flex;
    flex-direction: column;
}

.mobile_news_box2 {
    display: flex;
    flex-direction: column;
    padding-top: 100px;   
    align-items: center;
    justify-content: start;
}

.mobile_news_box_img {
    height: 275px;
    width: 295px;
}

.mobile_news_box_txt_group {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 290px;
}

.mobile_news_box_txt_top {
    color: #ffffff;
    font-family: "Pretendard", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.36px;
    line-height: 25.2px;
}

.mobile_news_box_txt_bottom {
    color: #cccccc;
    font-family: "Pretendard", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.24px;
    line-height: 16.8px;
}

.mobile_news_bg {
    height: 100%;
    width: 100%;
    background-image : url(./img/mobile_news_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    left : 0px;
    top : 0px;
    z-index: 0;
}

.mobile_news_title_group {
    display: flex;
    padding: 110px 0 0 0px;
    position: relative;
    z-index: 900;
    gap : 5px;
}