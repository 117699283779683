.partners {
    width: 100%;
}

.partners_overlap_group {
    height: 977px;
    position: relative;
    z-index: 900;
}

.partner_title {
    color: #ffffff;
    font-family: "Rajdhani", Helvetica;
    font-size: 80px;
    font-weight: 600;
    letter-spacing: -1.6px;
    line-height: 112px;
    white-space: nowrap;
    margin: 0;
    padding: 200px 0 0 60px;
    position: relative;
    z-index: 900;
}

.partner_logo_total_group {
    padding: 155px 0 0 60px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    z-index: 900;
}

.partner_logo_group {
    display: flex;
    gap: 20px;
}

.partner_logo_frame {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.partner_logo_img {
    height: 150px;
    width: 280px;
    background-color: #111111;
    border: 1px solid #333333;
    background-size: 100%;

}

.partner_logo_txt {
    padding: 10px;
    color: #ffffff;
    font-family: "Rajdhani", Helvetica;
    font-size: px;
    font-weight: 600;
    letter-spacing: -0.36px;
    line-height: 25.2px;
    white-space: nowrap;
    width: fit-content;
}

.partner_logo_frame_hide {
    opacity: 0;
}

.partners_bg {
    height: 977px;
    width: 1920px;
    width: 100%;
    background-image : url(./img/partners_bg.png);
    background-size: 100%;
    position: absolute;
    left : 0px;
    top : 0px;
    z-index: 0;
}

/* 모바일 */


.mobile_partners {
    width: 100%;
}

.mobile_partners_overlap_group {
    height: 700px;
    position: relative;
    z-index: 900;
    margin: 0 20px;
    border-left: 1px solid #444444;
    border-right: 1px solid #444444;
}

.mobile_partner_title {
    color: #ffffff;
    font-family: "Rajdhani", Helvetica;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: -1.6px;
    line-height: 112px;
    white-space: nowrap;
    margin: 0;
}

.mobile_partner_logo_total_group {
    padding: 120px 0 0 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    z-index: 900;
}

.mobile_partner_logo_group {
    display: flex;
    gap: 15px;
}

/* .mobile_partner_logo_frame {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mobile_partner_logo_img {
    height: 75px;
    width: 140px;
    background-color: #111111;
    border: 1px solid #333333;
    background-size: 100%;
}

.mobile_partner_logo_txt {
    padding: 10px;
    color: #ffffff;
    font-family: "Rajdhani", Helvetica;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.36px;
    line-height: 25.2px;
    white-space: nowrap;
    width: fit-content;
    text-align: center;
} */

.mobile_partner_logo_frame_hide {
    opacity: 0;
}

.mobile_partners_bg {
    height: 700px;
    width: 100%;
    background-image : url(./img/mobile_partners_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    left : 0px;
    top : 0px;
    z-index: 0;
}

.mobile_partners_title_group {
    display: flex;
    padding: 110px 0 0 0px;
    position: relative;
    z-index: 900;
    gap : 40px;
}