.mobile_box2_vetor {
    background-color: black;
    height: 669px;
    position: relative;
    margin: 0 20px;
    border-left: 1px solid #444444;
    border-right: 1px solid #444444;
}

 .mobile_box2_bg {
    height: 669px;
    width: 100%;
    background-image : url(./img/box2_bg2.png);
    background-size: 100% 100%;
    position: absolute;
    left : 0px;
    top : 550px;
    z-index: 900;
    background-repeat: no-repeat;
} 

.mobile_box2_overlap_group {
   display: flex;
   flex-direction: column;
   padding: 80px 0px 0px 0px;
   gap: 30px;
}

.mobile_AI {
    color: #ffffff;
    font-family: "Pretendard-SemiBold", Helvetica;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.48px;
    line-height: 33.6px;
    white-space: nowrap;
    margin: 0;
    z-index: 900;
}

.mobile_AML-CFT {
    color: #cccccc;
    font-family: "Pretendard-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.28px;
    line-height: 19.6px;
    padding-left: 40px;
    margin: 0;
    z-index: 900;
    white-space: nowrap;
}

.mobile_box2_point_group {
    display: flex;
    padding: 90px 0px 0px 40px;
    gap: 57px;
 }

 .mobile_box2_point_group2 {
    display: flex;
    padding: 50px 0px 0px 40px;
    gap: 57px;
 }

.mobile_point-text {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 10px;
}

.mobile_point-text-2 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 10px;
}

.mobile_point-text-3 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 10px;
}

.mobile_point-text-4 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 10px;
}

.mobile_point-text_text {
    color: #ffffff;
    font-family: "Rajdhani-SemiBold", Helvetica;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.28px;
    line-height: 19.6px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.mobile_point-text_text_2 {
   background: linear-gradient(180deg, rgb(22 215 205) 0%, rgb(0, 135, 205) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: #16D7CD;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -0.7px;
  line-height: 49px;
  position: relative;
  width: fit-content;
  white-space: nowrap;
}

.mobile_point-text_text_3 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(180deg, rgb(22 215 205) 0% 0%, rgb(0, 135, 205) 100%);
    background-clip: text;
    color: transparent;
    font-family: "Pretendard-Bold", Helvetica;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -0.7px;
    line-height: 49px;
    position: relative;
    width: fit-content;
    white-space: nowrap;
}
