@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@400&display=swap');

.box3 {
    position: relative;
}

.box3_overlap_group {
    display: flex;
}

.box3_bg {
    height: 400px;
    width: 100%;
    background-image : url(./img/box3_bg.png);
    background-color: black;
    position: absolute;
    left : 0px;
    top : 0px;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.box3_logo {
    width: 319px;
    height: 80px;
    padding: 100px 0px 0px 360px;
    margin: 0;
    filter: contrast(150%) brightness(120%);
 
}

.box3_arrow {
    filter: contrast(150%) brightness(120%);
    height: 54px;
    width: 734px;
    padding: 127.74px 0px 0px 0px;
    position: relative;
    left: -60px;
}

.box3_overlap_txt {
  display: inline;
  color: #FFFFFF;
  font-family: 'Arial', 'Helvetica', sans-serif;
  font-weight: 600;
  font-size: 20px; 
  margin: 0;
  position: relative;
  left: -60px;
  z-index: 900;
  padding: 131px 0px 0px 38.73px;
}

.box3_overlap_group2 {
    display: flex;
    gap : 30px;
}

.box3_overlap2_txt {
    color: #FFFFFF;
    font-family: 'Rajdhani', Helvetica, sans-serif;
    font-weight: 400;
    font-size: 80px; 
    letter-spacing: -1.6px;
    line-height: 112px;
    white-space: nowrap;
    z-index: 900;
    margin: 0;
    padding-left: 360px;
  }

  .box3_vector {
    margin: 0;
    filter: contrast(150%) brightness(120%);
    z-index: 900;
    position: relative;
    height: 55px;
    width: 79px;
    padding-top: 30px;
  }

  .box3_overlap2_txt2 {
    color: #FFFFFF;
    font-family: 'Rajdhani', Helvetica, sans-serif;
    font-weight: 400;
    font-size: 80px; 
    letter-spacing: -1.6px;
    line-height: 112px;
    white-space: nowrap;
    z-index: 900;
    margin: 0;
  }

  .box3_overlap2_txt3 {
    color: #FFFFFF;
    font-family: 'Rajdhani', Helvetica, sans-serif;
    font-weight: 400;
    font-size: 80px; 
    letter-spacing: -1.6px;
    line-height: 105px;
    white-space: nowrap;
    z-index: 900;
    margin: 0;
    position: relative;
    padding-left: 360px;
  }