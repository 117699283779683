@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;600&display=swap');

.box4 {
    position: relative;
    height: 1666px;
}

.box4_overlap_group {
    display: flex;
    flex-direction: column;
}

.box4_bg {
    height: 1666px;
    width: 100%;
    background-image : url(./img/box4_bg.png);
    position: absolute;
    left : 0px;
    top : 0px;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.box4_txt_top_group {
    position: relative;
    z-index: 900;
    align-items: center;
    display: inline-flex;
    gap: 10px;
}

.box4_txt_top {
    color: #999999;
    font-family: 'Rajdhani', Helvetica, sans-serif;
    font-weight: 400;
    font-size: 80px; 
    letter-spacing: -1.6px;
    line-height: 112px;
    white-space: nowrap;
    margin: 0;
    padding-left: 360px;
}

.box4_txt_ovj {
    position: relative;
}

.box4_txt_bottom{
    color: #777777;
    font-family: "Pretendard-Regular", Helvetica;
    font-weight: 400;
    font-size: 14px; 
    letter-spacing: -0.32px;
    line-height: 22.4px;
    padding-left: 660px;
    padding-top: 30px;
    margin: 0;
}

.box4_hover {
    display: flex;
    gap:282px;
}

.box4_txt_hover_group {
    display: flex;
    flex-direction: column;
    padding-top: 81.5px;
    padding-left: 642px;
    position: relative;
    z-index: 900;
}

.box4_txt_hover {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.box4_txt_hover_svg {
    position: absolute;
    top : 65px;
    left: 925px;
    z-index: 900;
    opacity: 0;
}

.box4_txt_hover_title {
    color: #dddddd;
    font-family: 'Rajdhani', Helvetica, sans-serif;
    font-weight: 600;
    font-size: 40px; 
    letter-spacing: -0.8px;
    line-height: 56px;
    white-space: nowrap;
    width: fit-content;
}

.box4_txt_hover_content {
    color: #dddddd;
    font-family: "Pretendard-Regular", Helvetica;
    font-weight: 400;
    font-size: 13.5px; 
    letter-spacing: -0.32px;
    line-height: 22.4px;
    width: fit-content;
}

.box4_img_hover2 {
    width: 36px;
    height: 268px;
    background-image: url('./img/box4_hover_img2.png');
}

.box4_img_hover3 {
    width: 36px;
    height: 268px;
    background-image: url('./img/box4_hover_img3.png');
    transition: background-image 0.5s ease;
}

.box4_img_hover4 {
    width: 36px;
    height: 268px;
    background-image: url('./img/box4_hover_img4.png');
    transition: background-image 0.5s ease;
}

.box4_img_hover5 {
    width: 36px;
    height: 48px;
    background-image: url('./img/box4_hover_img5.png');
    transition: background-image 0.5s ease;
}

.box4_txt_hover2 {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.box4_txt_hover3 {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.box4_txt_hover4 {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.box4_txt_hover5 {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.box4_txt_hover_svg2 {
    position: absolute;
    top : 335px;
    left: 925px;
    z-index: 900;
    opacity: 0;
}

.box4_txt_hover_svg3 {
    position: absolute;
    top : 605px;
    left: 925px;
    z-index: 900;
    opacity: 0;
}

.box4_txt_hover_svg4 {
    position: absolute;
    top : 865px;
    left: 925px;
    z-index: 900;
    opacity: 0;
}

.box4_txt_hover_svg5 {
    position: absolute;
    top : 1145px;
    left: 925px;
    z-index: 900;
    opacity: 0;
}
