.mobile_footer {
    height: 373px;
    position: relative;
    display: flex;
}

.mobile_footer_group {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 28px;
    position: relative;
    z-index: 900;
    box-sizing: border-box;
}

.mobile_footer_information_group{
    align-items: flex-start;
    padding-left: 20px;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
}

.mobile_footer_information_title {
    color: #ffffff;
    font-family: "Pretendard-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px;
    line-height: 22.4px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.mobile_footer_information_txt_frame {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 2px;
    position: relative;
}

.mobile_footer_information_txt_group{
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
}

.mobile_footer_information_txt{
    color: #777777;
    font-family: "Pretendard-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.28px;
    line-height: 19.6px;
    margin-top: -1px;
    position: relative;
    width: 128px;
}

.mobile_footer_information_txt2{
    color: #777777;
    font-family: "Pretendard-Medium", Helvetica;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.28px;
    line-height: 19.6px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.mobile_footer_information_txt3{
    color: #777777;
    font-family: "Pretendard-Medium", Helvetica;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.28px;
    line-height: 19.6px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.mobile_footer_copyright {
    align-items: center;
    justify-content: center;
    display: flex;
    padding-right: 30px;
}

.mobile_footer_copyright_txt{
    margin: 0;
    color: #777777;
    font-family: "Pretendard-Regular", Helvetica;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: -0.24px;
    line-height: 16.8px;
    white-space: nowrap;
}

.mobile_footer_top_btn {
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: fixed; 
    right: 15px; 
    bottom: 60px;
    z-index: 980;
}

.mobile_footer_bg {
    height: 100%;
    width: 100%;
    background-color: #111111;
    position: absolute;
    left : 0px;
    top : 0px;
    z-index: 0;
}
