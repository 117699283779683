.mobile_box {
    width: 100vw;
}

.mobile_main {
    height: 549px;
    width: 100%;
}

.mobile_overlap-group {
    position: relative;
}

.mobile_v-banner {
    background-image : url(../main/img/image\ 43.png);
    background-size: 100%;
    height: 549px;
    position: absolute;
    left : 0;
    top : 0;
    width: 100%;
}

.mobile_bg {
    background-color: #000000cc;
    height: 549px;
}

.mobile_overlap-group-flex {
    display: flex;
    flex-direction: column;
    position: relative;
}

.mobile_we-utilize-AI-to {
    color : #ffffff;
    font-family: 'Rajdhani', Helvetica, sans-serif;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: -0.64px;
    line-height: 38.4px;
    text-align: left;
    margin: 0;
    padding: 125px 0px 0px 20px;
}

.mobile_txt {
    margin-left: 116px;
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.mobile_vector {
    height: 79px;
    width: 11px;
    transform: scaleX(-1);
    padding: 60px 0px 0px 0px;
}

.mobile_img {
    height: 79px;
    width: 11px;
    padding: 60px 0px 0px 0px;
}

.mobile_onclev-is-an-IT {
    color : #ffffff;
    font-family: 'Rajdhani', Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.28px;
    line-height: 19.6px;
    text-align: left;
    padding: 68px 0px 0px 0px;
    margin: 0;
}