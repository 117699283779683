/* .box2 {
} */

.box2_vetor {
    background-color: black;
    height: 931px;
    position: relative;
}

 .box2_bg {
    height: 931px;
    width: 100%;
    background-image : url(./img/box2_bg.png);
    position: absolute;
    background-repeat: no-repeat;
    left : 0px;
    top : 0px;
    z-index: 0;
    background-size: 100% 100%;
} 

.box2_overlap_group {
   display: flex;
   flex-direction: column;
   padding: 150px 0px 0px 360px;
   gap: 30px;
}

.AI {
    color: #ffffff;
    font-family: "Pretendard-SemiBold", Helvetica;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: -0.8px;
    line-height: 56px;
    white-space: nowrap;
    /* padding: 150px 0px 0px 360px; */
    margin: 0;
    z-index: 900;
}

.AML-CFT {
    color: #cccccc;
    font-family: "Pretendard-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.32px;
    line-height: 22.4px;
    padding-left: 300px;
    margin: 0;
    z-index: 900;
}

.box2_point_group {
    display: flex;
    justify-content: center;
    padding: 90px 0px 0px 520px;
    gap: 82px;
 }

 .box2_point_group2 {
    display: flex;
    justify-content: center;
    padding: 100px 0px 0px 520px;
    gap: 82px;
 }

.point-text {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 10px;
}

.point-text-2 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 10px;
}

.point-text-3 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 10px;
}

.point-text-4 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 10px;
}

.point-text_text {
    color: #ffffff;
    font-family: "Rajdhani-SemiBold", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.32px;
    line-height: 22.4px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.point-text_text_2 {
   background: linear-gradient(180deg, rgb(22 215 205) 0%, rgb(0, 135, 205) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: #16D7CD;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 56px;
  font-weight: 700;
  letter-spacing: -1.12px;
  line-height: 78.4px;
  position: relative;
  width: fit-content;
}

.point-text_text_3 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(180deg, rgb(22 215 205) 0% 0%, rgb(0, 135, 205) 100%);
    background-clip: text;
    color: transparent;
    font-family: "Pretendard-Bold", Helvetica;
    font-size: 56px;
    font-weight: 700;
    letter-spacing: -1.12px;
    line-height: 78.4px;
    position: relative;
    width: fit-content;
}




