.box4_hover {
    display: flex;
    gap:282px;
    position: relative;
}

.box4_img_hover {
    width: 36px;
    height: 100%;
    transition: background-image 0.5s ease;
}

.box4_txt_hover_group {
    display: flex;
    flex-direction: column;
    padding-top: 81.5px;
    padding-left: 642px;
    position: relative;
    z-index: 900;
}

.box4_txt_hover {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.box4_txt_hover_svg {
    position: absolute;
    top : -15px;
    left: 290px;
    z-index: 900;
    opacity: 0;
}

.box4_txt_hover_title {
    color: #dddddd;
    font-family: 'Rajdhani', Helvetica, sans-serif;
    font-weight: 600;
    font-size: 40px; 
    letter-spacing: -0.8px;
    line-height: 56px;
    white-space: nowrap;
    width: fit-content;
}

.box4_txt_hover_content {
    color: #dddddd;
    font-family: "Pretendard-Regular", Helvetica;
    font-weight: 400;
    font-size: 13.5px; 
    letter-spacing: -0.32px;
    line-height: 22.4px;
    width: fit-content;
}

