.mobile_company_txt_bottom_group {
    padding-left: 40px;
    display: flex;
    flex-direction: column;
}

.mobile_company_bottom_group {
    display: flex;
    flex-direction: column;
}

.mobile_company_txt_bottom_title {
    color: #444444;
    font-family: "Pretendard", Helvetica;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: -0.64px;
    line-height: 44.8px;
    white-space: nowrap;
}

.mobile_company_txt_bottom_img {
    padding-left: 39px;
}

.mobile_company_txt_bottom_number {

    padding-left: 35px; 
    color: #444444;
    font-family: "Pretendard", Helvetica;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.4px;
    line-height: 28px;
    white-space: nowrap;
    text-align: center;
}

.mobile_company_txt_bottom_number2 {
    padding-left: 35px; 
    color: #444444;
    font-family: "Pretendard", Helvetica;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.4px;
    line-height: 28px;
    white-space: nowrap;
    text-align: center;
}

.mobile_company_txt_bottom_txt_group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #444444;
    font-family: "Pretendard", Helvetica;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.28px;
    line-height: 19.6px;
    white-space: nowrap;
    padding-top: 3px;
    padding-left: 30px;
}