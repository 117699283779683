.mobile_technology_list {
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-bottom: 1px solid #444444;
    position: relative;
}

.mobile_technology_list_txt_group {
    display: flex;
    flex-direction: column;
}

.mobile_technology_list_txt_top {
    color: #444444;
    font-family: "Rajdhani", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.28px;
    line-height: 19.6px;
    white-space: nowrap;
}

.mobile_technology_list_txt_bottom {
    color: #444444;
    font-family: "Rajdhani", Helvetica;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: -0.64px;
    line-height: 44.8px;
    white-space: nowrap;
}

.mobile_technology_list_txt_group2 {
    color: #444444;
    font-family: "pretendard", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.32px;
    line-height: 22.4px;
}

.mobile_technology_list_img {
    height: 174px;
    width: 335px;
    position: relative;
    /* left: -10px;
    top: 190px; */
    z-index: 901;
}

