.contact_form_button {
    background-color: #FFFFFF;
    border: 1px #dddddd;
    border-radius: 999px;
    height: 48px;
    width: 217.5px;
    padding: 10px 20px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s; /* 배경 색상 변화 애니메이션 */
}

.contact_form_button_active {
    background-color: #16d7cd;
    background: linear-gradient(270deg, rgb(0, 135, 205, 100) 0% ,#16d7cd 100%);
    border: 1px #ffffff33;
    border-radius: 999px;
    height: 48px;
    width: 217.5px;
    padding: 10px 20px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s; /* 배경 색상 변화 애니메이션 */
}

.contact_form_button_txt {
    color: #111111;
    font-family: 'Pretendard', Helvetica;
    font-weight: 500;
    font-size: 16px; 
    letter-spacing: -0.32px;
    line-height: 22.4px;
    white-space: nowrap;
    width: fit-content;
}

.contact_form_button_txt_active {
    color: #ffffff;
    font-family: 'Pretendard', Helvetica;
    font-weight: 500;
    font-size: 16px; 
    letter-spacing: -0.32px;
    line-height: 22.4px;
    white-space: nowrap;
    width: fit-content;
}


/* 모바일 */

.mobile_contact_form_button {
    background-color: #FFFFFF;
    border: 1px #dddddd;
    border-radius: 999px;
    height: 48px;
    width: 142.5px;
    padding: 10px 20px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s; /* 배경 색상 변화 애니메이션 */
}

.mobile_contact_form_button_active {
    background-color: #16d7cd;
    background: linear-gradient(270deg, rgb(0, 135, 205, 100) 0% ,#16d7cd 100%);
    border: 1px #ffffff33;
    border-radius: 999px;
    height: 48px;
    width: 142.5px;
    padding: 10px 20px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s; /* 배경 색상 변화 애니메이션 */
}

.mobile_contact_form_button_txt {
    color: #111111;
    font-family: 'Pretendard', Helvetica;
    font-weight: 500;
    font-size: 14px; 
    letter-spacing: -0.28px;
    line-height: 19.4px;
    white-space: nowrap;
    width: fit-content;
}

.mobile_contact_form_button_txt_active {
    color: #ffffff;
    font-family: 'Pretendard', Helvetica;
    font-weight: 500;
    font-size: 14px; 
    letter-spacing: -0.28px;
    line-height: 19.4px;
    white-space: nowrap;
    width: fit-content;
}