.mobile_partnerbox_logo_frame {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mobile_partnerbox_logo_img {
    height: 75px;
    width: 140px;
    border: 1px solid #333333;
    background-size: 100%;
    padding: 5px;
    box-sizing: border-box;
}

.mobile_partnerbox_logo_txt {
    padding: 10px;
    color: #ffffff;
    font-family: "Rajdhani", Helvetica;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.36px;
    line-height: 25.2px;
    white-space: nowrap;
    width: fit-content;
    text-align: center;
}