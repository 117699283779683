.technology_list {
    padding: 80px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-bottom: 1px solid #444444 ;
    position: relative;
}

.technology_list_txt_group {
    display: flex;
    flex-direction: column;
}

.technology_list_txt_top {
    color: #444444;
    font-family: "Rajdhani", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.36px;
    line-height: 25.2px;
    white-space: nowrap;
}

.technology_list_txt_bottom {
    color: #444444;
    font-family: "Rajdhani", Helvetica;
    font-size: 80px;
    font-weight: 600;
    letter-spacing: -1.6px;
    line-height: 112px;
    white-space: nowrap;
}

.technology_list_txt_group2 {
    color: #444444;
    font-family: "pretendard", Helvetica;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: -0.48px;
    line-height: 33.6px;
}

.technology_list_img {
    height: 566px;
    width: 1026px;
    position: absolute;
    left: 867px;
    top: -80px;
}

.technology_list_ovj {
    height: 236px;
    width: 237px;
    position: absolute;
    left: 789px;
    top: -150px;
    opacity: 0;
    z-index: 901;
}
